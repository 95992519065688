/* cyrillic-ext */
@font-face {
  font-family: 'RalewayRegular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('./assets/fonts/Raleway/Raleway-Regular.ttf'), local('./assets/fonts/Raleway/Raleway-Regular.ttf'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic-ext */
@font-face {
  font-family: 'RalewayMedium';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('./assets/fonts/Raleway/Raleway-Medium.ttf'), local('./assets/fonts/Raleway/Raleway-Medium.ttf'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'RalewayBold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('./assets/fonts/Raleway/Raleway-Bold.ttf'), local('./assets/fonts/Raleway/Raleway-Bold.ttf'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'RalewayExtra';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('./assets/fonts/Raleway/Raleway-ExtraBold.ttf'), local('./assets/fonts/Raleway/Raleway-ExtraBold.ttf'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gTD_u50.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic-ext */
@font-face {
  font-family: 'MadeSonara';
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/MadeSonara/MADESONARA1.otf") format('opentype');
}

/* cyrillic-ext */
@font-face {
  font-family: 'MadeSonara2';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('./assets/fonts/MadeSonara/MADE SAONARA PERSONAL USE.otf') format('opentype');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}


body {
  margin: 0;
  font-family:  'RalewayRegular',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #223644;
}

.RalewayMedium{
  font-family:  'RalewayMedium',sans-serif;
  font-size: 500;
}

.RalewayBold{
  font-family:  'RalewayBold',sans-serif;
  font-size: 700;
}

.RalewayExtra{
  font-family:  'RalewayExtra',sans-serif;
  font-size: 800;
}
.MadeSonara{
  font-family:  'MadeSonara', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactModal__Overlay{
	background-color: unset !important
}

.ReactModal__Content{
  right: 20px !important;
  top: 80px !important;

}

.ParentLi li{
	text-align: left !important;
  font-size: 18px;
  color: #223644;
  padding-left: 20px;
}

.MuiAccordionSummary-content{
  margin: 0 !important;
}

.MuiAccordionDetails-root{
  padding: 8px 16px 16px 130px !important;
}

.MuiAccordionDetails-root{
  padding: 8px 16px 16px 130px !important;
}

@media(max-width: 450px){
  .MuiAccordionDetails-root{
    padding: 8px 16px 16px !important;
  }
}

textarea {
  width: 100% !important;
}

.ReactModal__Content{
  padding: 0 !important;
}