.NavigationItem{
	margin:10px 0;
	width: 100%;
	box-sizing: border-box;
	display: block;	
}


.NavigationItem a{
	display: flex;
	align-items: center;
	color:black;
	text-decoration: none;
	width: 100%;
	box-sizing: border-box;
	display: block;
	font-size: 22px;	
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active{
	/*color:#40A4C8;*/
}

@media(min-width: 700px){
	.NavigationItem{
	margin:0;
	padding:0;
	height: 100%;
	width: auto;
	display: flex;
	align-items: center;	
	}


	.NavigationItem a{
		color:black;
		display: flex;
		align-items: center;
		font-size: 24px;
		padding:16px 10px;
		height: 100%;
		width: auto;	
	}

	.NavigationItem a:hover,
	.NavigationItem a:active,
	.NavigationItem a.active{
		/* background-color: #8F5C2C; */
		border-bottom: 4px solid #C9B069;
		/* color:black; */
	}
}