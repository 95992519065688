.css-6ibwe0{
  filter:brightness(0) invert(1) !important;
}
select:focus{
	outline: none;
}
.SelectLanguageB + .css-6ibwe0{
	filter:brightness(0) !important;
}
.fixed.white > div > div > div.language_white{
	display: none !important;
}

div:not(.fixed) > div > div > div.language{
	display: none !important;
}