.Container{
	display: flex !important;
	box-sizing: border-box !important;
	flex-direction: column !important;
	height: 100% !important;
	/* min-height: calc(100vh - 50px) !important; */
	padding-right:0 !important;
	padding-left: 0 !important;
	padding-bottom:0px !important;
	background-color: #FFFFFF !important;
}
.italicUnderline{
	text-decoration: underline;
}

@media (min-width: 10px) and (max-width: 699px){
	.Container{
		min-height: 90vh;
	}

	.CopyContainer {
		padding-top: 40px!important;
	}
}

@media (max-width:  699px) and (min-height: 640px) {
	.Container{
		min-height: 76vh;
	}
}
@media (min-width: 700px) and (max-width: 1099px){
	/*.italicUnderline{
		display: none !important;
	}*/
	.Container{
		height: fit-content !important;
		padding-right:0 !important;
		padding-left: 0 !important;
		padding-bottom:0px !important;
	}
}
@media (min-width: 1100px){
	.Container{
		height: fit-content !important;
		padding-left: 70px !important;
		padding-right:0 !important;
		padding-left: 80px !important;
		padding-bottom:0px !important;
	}
	/*.italicUnderline{
		display: none;
	}*/
}

