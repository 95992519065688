.SideDrawer{
	position:fixed;
	width:100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 200;
	background-color: #223644;
	padding: 32px 16px;
	box-sizing: border-box;
	transition: transform 0.3s ease-out;
}
.sideDrawerClose{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 50px
}

@media (min-width: 1100px){
	.SideDrawer{
		display: none;
}
}

.Open{
	transform: translateX(0);
}

.Close{
	transform: translateX(-100%);
}

.Logo{
	height: 11%;
	margin-bottom: 32px;

}

