.Container{
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	/* height: fit-content; */
	padding:0px 15px 50px;
	background-color: white;
	margin-top: 50px;
}

.titleOpinion{
	text-shadow: 0px 3px 6px #00000029;
}

.ButtonContainer{
	height: 70px;
}
.Button{
 border:none;
	height: 55px;
	width: 157px;
	background-color: #223644;
	color:white;
	font-size: 16px;
	cursor: pointer;
}
.Button:focus{
	outline: none;
}
.Button:hover{
	border: 1px solid #223644;
	background-color: white;
	transition: ease .3s;
}
.Button:hover img{
	filter: brightness(0)invert(1);
}

.Button:hover div div{
	color:#223644 !important;
}

.Bottom{
	flex:1;
	position: relative;
	align-items: center;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}
.BottomText{
	color:white;
}

.AbsoluteBox{
	z-index: 0;
	position: absolute;
	background-color: #223644;
	width: calc(100% - 55px);
	height: 100%; 
	right: 0;
	top:0;
	margin-right: -15px !important;
}

.AbsoluteArrow{
	position: absolute;
	z-index: 10;
	top:135px;
	cursor:pointer;
	filter:brightness(0) invert(1);
}
.Grab :active{
	cursor: grabbing;
}
.Grab{
 cursor: grab;
}

.ratingContainer{
	display: flex;
	flex-direction: column;
	font-size: 18px;
	letter-spacing: 0.9px;
	line-height: 21px;
}

.ratingTitle{
	font-weight: bold;
}

.ratingSubtitle{
	margin-top: 9px;
}

.ratingStarsContainer{
	margin-top: 12px ;
	display: flex;
	justify-content: center;
	gap: 4px;
	margin-bottom: 90px;
}

.dotSlider{
	display: block;
	height: 14px;
	width: 14px;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cdefs%3E%3Cstyle%3E.a,.c%7Bfill:none;%7D.a%7Bstroke:%23263643;%7D.b%7Bstroke:none;%7D%3C/style%3E%3C/defs%3E%3Cg class='a'%3E%3Ccircle class='b' cx='7' cy='7' r='7'/%3E%3Ccircle class='c' cx='7' cy='7' r='6.5'/%3E%3C/g%3E%3C/svg%3E");
}

.customDots :global(li.slick-active) label {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23223644;%7D%3C/style%3E%3C/defs%3E%3Ccircle class='a' cx='7' cy='7' r='7'/%3E%3C/svg%3E") !important;

}




@media screen and (max-width: 1200px){
	.textOpinion{
		margin-top: 15px !important;
		font-size: 14px !important;
	}
}




@media screen and (max-width: 699px){
	.Container{
		padding:0px 15px 300px;
		margin-top: 0;
	}

	/* .opinionBox{
		min-height: unset !important;
	} */

	.textOpinion{
		font-size: 14px !important; 
	}
	
}
@media (min-width: 700px) and (max-width: 1099px){
	.Container{
		padding:0px 50px 190px;
		margin-top: 70px;
	}

	.textOpinion{
		font-size: 16px !important; 
	}

	/* .opinionBox{
		min-height: unset !important;
	} */
}

@media (min-width: 1100px){
	.Container{
		padding-right: 80px;
		padding-left:80px;
		padding-bottom:190px;
		padding-top: 0px;
		margin-top: 90px;
	}
	.AbsoluteBox{
		height: calc(100% + 40px);
		margin-top: -20px !important;
		margin-bottom: -20px !important;
		
	}	
}


@media (min-width: 10px) and (max-width: 348px){
	.opinionBox{
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	
	.textOpinion{
		font-size: 14px !important;
		line-height: 20px !important;
		/* margin-top: 40px !important; */
	}

	.titleOpinion{
		margin-bottom: 50px !important;
		font-size: 40px !important;
	}
}