.App {
  text-align: center;
}
body{
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
*{
  box-sizing: border-box;
}

.ln-28{
  line-height:28px
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.dis-n{
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiButton-root{
  width: 200px !important;
  border-radius: 25px!important;
  background-color: #0076FF!important;
  color:white!important; 
  height: 50px!important;
  font-size: 16px !important;
  font-weight: 700 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.MuiButton-root:hover{
  transform: scale(1.1) !important;
}

.activeNav{
 color:#0076ff !important;
 transform: scale(1.1) !important;
}

.getBig:hover{
  transform: scale(1.1) !important;
}

.ZoomInContainer{
    position: relative;
    cursor: pointer;
}

.ZoomIn{
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 1s;
}



.getArrowWide:hover{
  width:33px;
}

.MaxDimensions{
  flex:1;
  display: flex;
  flex-direction: column;
}
@media (min-width: 10px) and (max-width: 699px){
  .MaxDimensions{
    height: 100%;
    width: 100%;
    max-width: 600px;
    margin:0 auto;
  }
  #SectionTemplate .MaxDimensions{
    max-width: 100%;
  }

  #NosotrosContainer__2 .MaxDimensions{
    max-width: 100%;
  }

  #NosotrosContainer__3 .MaxDimensions{
    max-width: 100%;
  }
  .dis-xs-n{
    display: none !important;
  }
  .mt-xl--50{
    margin-top: -20px !important;
  }
  .IPadOnly{
    display: none !important;
  }
  .DeskOnly{
    display: none !important;
  }
}
@media (min-width: 700px) and (max-width: 1099px){
  .MaxDimensions{
    height: 100%;
    width: 100%;
    max-width: 800px;
    margin:0 auto;
  }

  #SectionTemplate .MaxDimensions{
    max-width: 100%;
  }

  #NosotrosContainer__2 .MaxDimensions{
    max-width: 100%;
  }

  #NosotrosContainer__3 .MaxDimensions{
    max-width: 100%;
  }

  .dis-md-n{
    display: none !important;
  }
  .mt-xl--50{
    margin-top: -50px !important;
  }
  .MobileOnly{
    display: none !important;
  }
  .DeskOnly{
    display: none !important;
  }
}
@media (min-width: 1100px){
  .ZoomIn:hover{
    transform: scale(1.2);
  }
  .ZoomSibling:hover + .ZoomIn {
    transform: scale(1.2);
  }
  .ZoomInContainer{
    overflow: hidden;
  }
  .MaxDimensions{
    height: 100%;
    width: 100%;
    max-width: 1440px;
    margin:0 auto;
  }
  #Nosotros1 .MaxDimensions{
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin:0 auto;
  }
  #Nosotros2 .MaxDimensions{
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin:0 auto;
  }
  #SectionTemplate .MaxDimensions{
    height: 100%;
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
  }
  #NosotrosContainer__2 .MaxDimensions{
    height: 100%;
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
  }
  
  #NosotrosContainer__3 .MaxDimensions{
    height: 100%;
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
  }

  .MobileOnly{
    display: none !important;
  }
  .IPadOnly{
    display: none !important;
  }
  .dis-xl-n{
    display: none !important;
  }
  .mt-xl--50{
    margin-top: -50px !important;
  }
}
.fixed{
  position: fixed !important;
  transition: 1s ease !important;
}

.carousel{ height: 100%;width: 100% }
.slick-list { height: 100%; }
.slick-prev { 
  left: 0;
  height: 100%!important;
  z-index: 1;
}
.slick-next { 
  right: 0;
  height: 100%!important;
}
.slick-track { height: 100% }

:focus {
    outline: none !important;
}


.slick-slide > div {
  display: flex!important;
  justify-content: center!important;
  align-items: baseline!important;
}

/*Fullscreen modal*/
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
/*Fullscreen modal*/
.whiteHeader{
  background-color: white !important;
  transition: 1s ease !important;
  box-shadow: #00000029 0 3px 6px;
}