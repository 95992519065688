.Logo{
    height: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-end;
    cursor:pointer;
}

.Logo img{
	width: 150px;
	height: auto;
}

@media(max-width: 699.98px){
	.Logo{
	    justify-content: flex-start;
	}
}
