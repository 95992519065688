
.contact{
	display: flex;
	justify-content: space-between;
	padding: 92px 0px 92px 78px;
	width: 100%;
	background-color: #F8F8F8;
	position: relative;
}

.contactLeft{
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
}

.contactRight{
	flex: 1;
	display: flex;
	justify-content: flex-end;
	z-index: 2;
}

.Title{
	font-size: 50px;
	color: #223644;
	line-height: 59px;
	letter-spacing: 2.5px;
	margin-bottom: 11px;
}

.Form{
	width: 100%;
	display: flex;
	flex-direction: column;
}

.InputContainer{
	margin-top: 60px;
	display: flex;
	flex-wrap: wrap;
    gap: 25px;
	
}

.InputContainer > div {
	width: 40%;
	flex-grow: 1;
}

.radioContainer{
	margin-top: 11px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 20px;
}


.ButtonContainer{
	margin-top: 30px;
	height: 100px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}
.Button{
	height: 50px;
	width: 200px;
	background-color: transparent;
	border:1px solid white;
	color:white;
	font-size: 16px;
	cursor: pointer;
}
.Button:focus{
	outline: none;
}
.Button{
	background-color: #223644;
	transition: ease .3s;
	color:#C9B069 !important;
}

.tagImg{
	width: 90%;
}

.contactImg{
	width: 100%;
	height: 100%;
	box-shadow: -15px 15px 30px #00000029;
	object-fit: cover;
}

.backgroundImg{
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 419px;
	background-color: white;
	z-index: 1;
}

.error{
	font-size: 11px;
	color: red;
	margin-top: 10px;
}

@media (max-width: 1098px){
	.contact{
		flex-direction: column;
		padding: 0;
	}

	.contactLeft{
		padding: 50px 31px 0px 31px;
	}

	.tagImg{
		width: 100%;
	}

	.backgroundImg{
		display: none;
	}

	.ButtonContainer{
		align-items: flex-start;
	}
} 

@media (max-width: 700px) {
	.contactRight{
		justify-content: center;
	}
	

	.InputContainer{
		flex-direction: column;
	}
	
	.InputContainer > div {
		width: 100%
	}


	.radioContainer{
		grid-template-columns: repeat(2, 1fr);
		column-gap: 10px;
	}
}