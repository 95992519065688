.Container{
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	padding:20px 25px;
	background-color: #223644;
}
.Bottom{
	flex:1;
	position: relative;
	align-items: center;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}
.BottomText{
	color:#C9B069;
}
.verticalLine{
	margin:0 5px;
	color: #C9B069
}
@media (min-width: 10px) and (max-width: 699px){
	.verticalLine{
		display: none
	}
}
@media (min-width: 700px) and (max-width: 1099px){
	.Bottom{
		flex-direction: row;
		justify-content: center;
	}
	.MobileOnly{
		display: none;
	}
	.BottomText{
		font-size: 18px;
	}
}
@media (min-width: 1100px){
	.Bottom{
		flex-direction: row;
		justify-content: center;
	}
	.MobileOnly{
		display: none;
	}
	.BottomText{
		font-size: 18px;
	}
}