.NavigationItems {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
	height: 100%;
	flex-flow: column;
	justify-content: flex-start;
}

.NavigationItem {
	margin: 10px;
	width: 100%;
	box-sizing: border-box;
	display: block;
}

.NavigationItemMobile {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	border-bottom: 0.5px solid rgba(234, 234, 234, 0.4);
	padding-bottom: 37px;
}

.NavItemMobile {
	text-decoration: none !important;
	color: #C9B069;
	display: flex;
	align-items: center;
	/* border: 3px solid green; */
	font-size: 18px;
	line-height: 21px;
	margin-top: 35px;
	
}


.resetService{
	border-bottom: none;
}

.NavItem {
	text-decoration: none !important;
	color: #C9B069;
	padding: 17px 23px;
	cursor: pointer;
	font-size: 16px;
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
}

.NavigationItemDropDown {
	margin: 10px;
	width: 100%;
	box-sizing: border-box;
	display: block;
}

.NavItemDropDownContainer{
	/* border: 3px solid red; */
	width: 100%;
	height: 62px;
	display: flex;
	align-items: center;
	color: #C9B069;
	cursor: pointer;	
}

.NavItemDropDown{
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 55px;
	font-size: 18px;
}

.NavItemDropDownContainer:hover{
	background-color: #EAEAEA34;
}


@media (max-width: 1100px) {
	.NavItem2 {
		padding: 17px 0px !important;
	}
	/* .NavigationItem,
	.NavigationItemDropDown{
		border-bottom: 1px solid #EAEAEA;
	} */
}

.NavItem2 {
	text-decoration: none !important;
	color: #C9B069;
	padding: 17px 23px;
	cursor: pointer;
	font-size: 18px;
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
}

@media(min-width: 1100px) {
	.NavigationItems {
		margin: 0px!important;
		flex-flow: row;
	}
	.NavItem {
		font-size: 16px !important;
		padding: 17px 33px;
	}
	.NavItem2 {
		font-size: 16px !important;
		padding: 17px 33px;
	}
	.NavigationItem {
		margin: 0;
		padding: 0;
		height: 100%;
		width: auto;
		display: flex;
		align-items: center;
	}
	.NavigationItemDropDown {
		margin: 0;
		padding: 0;
		height: 100%;
		width: auto;
		display: flex;
		align-items: center;
	}
}

@media(min-width: 700px) {
	.NavItem {
		font-size: 24px;
	}
	.NavItem2 {
		font-size: 24px;
	}
	.NavigationItem {
		margin: 27px 0;
	}
	.NavigationItemDropDown {
		margin: 27px 0;
	}
}

@media(max-width: 1100px) {
	.NavigationItem, .NavigationItemDropDown {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.NavItem:hover, .NavItem:active, .NavItem.active {
	/*background-color: #373e50 !important*/
	border-bottom: 4px solid #C9B069;
	/* background-color: white !important; */
	/* color:#223644; */
}

.NavItem2:hover, .NavItem2:active, .NavItem2.active {
	/*background-color: #373e50 !important*/
	border-bottom: 4px solid #C9B069;
	/* color:white; */
}

.activeLink > span {
	font-weight: bold !important;
	text-decoration: underline !important;
}


.hoverStyles:hover .hoverbolder {
	font-weight: 900 !important;
}

.hoverStyles:hover {
	background-color: #F8F8F8 !important;
}


.desktopMenuDropdown{
	/* border: 4px solid salmon !important; */
	margin-bottom: 20px;
	margin-top: 20px;
	background-color: white;
	position: absolute;
	top: 60px;
	right: 20px;
	height: 340px;
	width: 1000px;
	border: 1px solid #00000029;
	border-radius: 5px;
	
}

.containerDesktopMenuDropdown{
	width: 100%;
	height: 100%;
	display: flex;
}

.desktopMenuDropdownItem{
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-right: 1px solid #00000029;
}

.desktopMenuDropdownItemTextContainer{
	margin-top: 20px;
	flex: 1;
}


.desktopMenuDropdownItemImgContainer{
	padding-top: 18px;
	padding-bottom: 18px;
	flex: 1;
	display: flex;
	align-items: flex-end;
}

.desktopMenuDropdownItemImgContainer img{
	filter: brightness(0);
}

.desktopMenuDropdownItemText{
	font-size: 18px;
	color:#5D656F;
}

.desktopMenuDropdownItem:hover .desktopMenuDropdownItemText{
	font-weight: 900;
}