.container{
	height: 100%;
	width:100%;
}

.coverMainContainer{
	display: flex;
	justify-content: center;
}

.coverContainer{
	display: flex;
	width: 100%;
	max-width: 1520px;
	padding: 88px 131px 74px 131px;
}

.coverTitleContainer{
	flex: 1;
	display: flex;
	align-items: center;
}

.coverTitle{
	font-size: 45px;
	line-height: 53px;
	font-weight: bold;
	text-align: left;
	letter-spacing: 2px;
}

.coverSubtitleContainer{
	flex: 1;
	text-align: left;
	letter-spacing: 1px;
	display: flex;
	justify-content: flex-start;
}

.coverSubtitle{
	font-size: 22px;
	width: 80%;
	line-height: 32px;
}

.subtitleSpan{
	display: flex;
}

.subtitleList{
	list-style: none;
	padding: 0;
	
}

@media screen and (max-width: 1160px) {
	.coverContainer{
		padding: 88px 30px 74px 131px;
	}
}

@media screen and (max-width: 1110px) {
	.coverContainer{
		padding-left: 50px;
	}

	.coverSubtitle{
		font-size: 18px;
		
	}
}

@media screen and (max-width: 885px) {
	.coverContainer{
		flex-direction: column;
	}

	.coverTitleContainer{
		padding-bottom: 50px;
	}
	
	.coverSubtitle{
		width: 100%;
		padding-left: 10px;
	}
}

@media screen and (max-width: 695px) {
	.coverContainer{
		padding-left: 15px;
		padding-right: 23px;
	}

	.coverTitle{
		font-size: 35px;
		line-height: 35px;
		width: 80%;
	}
	.coverSubtitle{
		width: 100%;
		padding-left: 0;
	}
}

@media screen and (max-width: 468px) {
	.coverSubtitle{
		width: 98%;
	}

	.coverTitle{
		font-size: 30px;
	}

	.subtitleList{
		text-indent: -14px;
		padding-left: 15px;
	}
}


@media screen and (min-width: 1600px){
	.coverContainer{
		padding: 88px 0px 74px 0px;
	}
	.coverTitle {
		margin-left: 131px;
	}
}