.DrawerToggle {
    /*width: 40px;*/
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
   
}

.Text{
    color:white;
    margin-right: 10px;
}

.Text2{
    color:black;
    margin-right: 10px;
}

.Image2{
    filter: brightness(0);
}

@media (min-width: 1100px) {
    .DrawerToggle {
        display: none;
    }
}