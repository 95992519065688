.Input{
	/*width: 100%;
	padding:10px;*/
	box-sizing: border-box;
	text-align: left;
	width: 100%;
	
}


.Label {
	display: block;
	margin-bottom: 8px;
	color:#5D656F; 
	font-size: 14px;
}

.InputElement {
	width: 100%;
	text-align: left;
	height: 45px;
	color: black;
	font-size: 18px;
	border:none;
	border-radius: 8px;
	padding-left: 20px;
	padding-top: 5px;
	background-color: white;
	box-shadow: 0px 3px 6px #00000029;
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
	resize: none;

}
.InputElement::placeholder{
	color:black;
}

.InputElement:focus {
	outline: none;
	background-color: white;
}

.Input textarea{
	height: 86px;
}

.Invalid{
	border: 1px solid red;
	background-color: #ffcccb;

}

.ValidationError {
	 color:red;
	 margin:5px 0;
	 font-size: 11px;
}


.customRadio input{
	display: none;
}

.radioButton{
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
	width: 100%;
	height: 115px;
	background-color: white;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
}

.radioImg{
	width: 52px;
	height: 52px;
	background-repeat: no-repeat;
	background-position: center;
}


.radioButton:hover, .radioButtonSelected, .radioButton:hover .getFill > svg path {
	background-color: #223644;
	color: #C9B069;
	fill: #C9B069;
	
}

.selected{
	background-color: #223644;
	color: #C9B069;
}

.filled > svg path {
	fill: #C9B069;
}





@media (min-width: 700px) and (max-width: 1099px){
	.InputElement{
		max-width: none;
		width: 100%;
	}

}


@media (max-width: 700px) {
	/* .radioButton{
		width: 100%;
	} */
}