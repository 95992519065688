.Container{
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	height: fit-content;
	padding:50px 15px;
	background-color: #FFFFFF;
}

.ButtonContainer{
	height: 70px;
}
.Button{
 border:none;
	height: 55px;
	width: 300px;
	background-color: #325FB0;
	color:white;
	font-size: 18px;
	font-weight: 700;
	cursor: pointer;
}
.Button:focus{
	outline: none;
}
.Button:hover{
	border: 1px solid #223644;
	background-color: white;
	transition: ease .3s;
}
.Button img{
	filter: brightness(0)invert(1);
}
.Button:hover img{
	filter: brightness(1);
}

.Button:hover div div{
	color:#223644 !important;
}

.Bottom{
	flex:1;
	position: relative;
	align-items: center;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}
.BottomText{
	color:white;
}

.AbsoluteTextBox{
	z-index: 20;
	position: absolute; 
	
}

.AnimatedFromBottom{
	width:100%;
	flex-direction:column;
	/* border-left:1px solid #2E333E; */
	padding-left:15px;
	margin-top:30px;
	margin-bottom:70px;
	justify-content:center; 
	align-items:flex-start;
}

@media (min-width: 10px) and (max-width: 699px){
	.Container{
		height: fit-content;
	}
	.AbsoluteTextBox{ 
		left: 50%;
		bottom:-36px;
		transform: translate(-50%,0);
	}
	.AnimatedFromTop{
		width:100%;
	}
}
@media (min-width: 700px) and (max-width: 1099px){

	.AnimatedFromTop{
		width:100%;
	}
	.AbsoluteTextBox{ 
		left: -10px;
		bottom:36px;
	}
	.Container{
		padding:70px 50px 100px;
	}
}
@media (min-width: 1100px){
	.AnimatedFromBottom{
		width:50%;
		margin-top:0;
		margin-bottom:0;
	}
	.AnimatedFromTop{
		width:50%;
	}
	.AbsoluteTextBox{ 
		left: -10px;
		bottom:36px;
	}
	.Container{
		padding-right:80px;
		padding-left:80px;
		padding-bottom:90px;
		padding-top: 90px;
	}
	.AbsoluteBox{
		height: calc(100% + 40px);
		margin-top: -20px !important;
		margin-bottom: -20px !important;
		
	}	
}

