.Container{
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	height: fit-content;
	/* padding:0px 15px 0; */
	background-color: #FFFFFF;
}

.Container *{
	line-height: 35px;
	letter-spacing: 0.9px;
}

.containerSection{
	max-height: 454px;
}

.ButtonContainer{
	height: 70px;
}
.Button{
 border:none;
	height: 55px;
	width: 225px;
	background-color: #325FB0;
	color:white;
	border:1px solid white;
	font-size: 18px;
	font-weight: 700;
	cursor: pointer;
}
.Button:focus{
	outline: none;
}
.Button:hover{
	border: 1px solid #223644;
	background-color: white;
	transition: ease .3s;
}
.Button img{
	filter: brightness(0)invert(1);
}
.Button:hover img{
	filter: brightness(1);
}

.Button:hover div div{
	color:#373E50 !important;
}

.Button2{
 border:none;
	height: 55px;
	width: 220px;
	background-color: #325FB0;
	color:white;
	border:1px solid white;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
}
.Button2:focus{
	outline: none;
}
.Button2:hover{
	border: 1px solid #223644;
	background-color: white;
	transition: ease .3s;
}
.Button2 img{
	filter: brightness(0)invert(1);
}
.Button2:hover img{
	filter: brightness(1);
}

.Button2:hover div div{
	color:#373E50 !important;
}

.Bottom{
	flex:1;
	position: relative;
	align-items: center;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}
.BottomText{
	color:white;
}

.AbsoluteBox{
	z-index: 0;
	position: absolute;
	background-color: #223644;
	width: calc(100% - 55px);
	height: 100%; 
	right: 0;
	top:0;
	margin-right: -15px !important;
}



.ulParent  {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	/* max-width: 878px; */
	justify-content: center;
	/* border: 3px solid pink; */
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}
.ulParent li {
    margin-bottom: 1em;
    padding-left: 1.5em;
    position: relative;
}
    
.ulParent li:after {
	content: '';
	height: 10px;
	width: 10px;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.563' height='7.552' viewBox='0 0 7.563 7.552'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23182935;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M1487.367,1593.121h-1.148a4.17,4.17,0,0,0-.428-1.663,2.861,2.861,0,0,0-2.775-1.539l0-1.148a4.16,4.16,0,0,0,1.665-.427,2.862,2.862,0,0,0,1.539-2.775h1.148a3.845,3.845,0,0,0,.97,2.434,2.976,2.976,0,0,0,2.21.768h.023l.008,1.148h-.016a4.18,4.18,0,0,0-1.655.427A2.862,2.862,0,0,0,1487.367,1593.121Zm-2.073-3.8a1.49,1.49,0,1,0,2.979.066,3.619,3.619,0,0,1-.748-.568,3.961,3.961,0,0,1-.71-.959A3.827,3.827,0,0,1,1485.294,1589.318Z' transform='translate(-1483.015 -1585.569)'/%3E%3C/svg%3E");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	position: absolute;
	top: 0.8em;
	left: 0;
}

.ulParent  * + * {
	margin-left: 100px;
	/* border: 3px solid red; */
}







@media (min-width: 10px) and (max-width: 699px){
	.Container{
		height: fit-content;
	}
	.Button{
		display: none;
	}
	.ulParent  {
		flex-direction: column;
		margin: 0 !important
	}
	.ulParent * + * {
		margin-left: 0;
	}

	.ulParent  li div{
		text-align:left
	}

	.containerSection{
		max-height: 100%;
	}

}
@media (min-width: 700px) and (max-width: 1099px){
	.Container{
		/* padding:0px 50px 0px; */
	}
	.Button2{
		display: none;
	}
	.ulParent  {
		margin: 0 !important
	}

	.ulParent * + * {
		margin-left: 20px;
	}

	.ulParent  li div{
		text-align:left
	}

	.containerVisionMision{
		padding-right:35px !important;
		padding-left:35px !important;
	}
	
}
@media (min-width: 1100px){
	.Container{
		/* padding-left:80px;
		padding-right:80px;
		padding-bottom:0px;
		padding-top: 0px; */
	}
	.AbsoluteBox{
		height: calc(100% + 40px);
		margin-top: -20px !important;
		margin-bottom: -20px !important;
		
	}
	.Button2{
		display: none;
	}

	.containerVisionMision{
	
		padding-right:65px !important;
		padding-left:65px !important;
	}

	.ulParent * + * {
		margin-left: 110px;
	}
}

@media (min-width: 1330px) {
	.containerVisionMision{
	
		padding-right:0px !important;
		padding-left:0px !important;
	}
}


@media (max-width: 320px){
	.misionText{
		margin-left: 10px !important;
	}
}