.Container{
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	height: fit-content;
	/* padding:0px 15px 0; */
	background-color: #FFFFFF;
}

.Container *{
	line-height: 35px;
}

.containerSection{
	max-height: 454px;
}

.ButtonContainer{
	height: 70px;
}
.Button{
 border:none;
	height: 55px;
	width: 225px;
	background-color: #325FB0;
	color:white;
	border:1px solid white;
	font-size: 18px;
	font-weight: 700;
	cursor: pointer;
}
.Button:focus{
	outline: none;
}
.Button:hover{
	border: 1px solid #223644;
	background-color: white;
	transition: ease .3s;
}
.Button img{
	filter: brightness(0)invert(1);
}
.Button:hover img{
	filter: brightness(1);
}

.Button:hover div div{
	color:#373E50 !important;
}

.Button2{
 border:none;
	height: 55px;
	width: 220px;
	background-color: #325FB0;
	color:white;
	border:1px solid white;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
}
.Button2:focus{
	outline: none;
}
.Button2:hover{
	border: 1px solid #223644;
	background-color: white;
	transition: ease .3s;
}
.Button2 img{
	filter: brightness(0)invert(1);
}
.Button2:hover img{
	filter: brightness(1);
}

.Button2:hover div div{
	color:#373E50 !important;
}

.Bottom{
	flex:1;
	position: relative;
	align-items: center;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}
.BottomText{
	color:white;
}

.AbsoluteBox{
	z-index: 0;
	position: absolute;
	background-color: #223644;
	width: calc(100% - 55px);
	height: 100%; 
	right: 0;
	top:0;
	margin-right: -15px !important;
}


.overlayImg{
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(0deg, rgba(34, 54,68, 0.3), rgba(34, 54, 68, 0.3));
}


@media (min-width: 10px) and (max-width: 699px){
	.Container{
		height: fit-content;
	}
	.Button{
		display: none;
	}

	.containerSection{
		max-height: 100%;
	}
}
@media (min-width: 700px) and (max-width: 1099px){
	.Container{
		/* padding:0px 50px 0px; */
	}
	.Button2{
		display: none;
	}

	.containerWeCover{
		padding-right:35px !important;
		padding-left:35px !important;
	}

	.containerSection{
		max-height: 100%;
	}
}
@media (min-width: 1100px){
	.Container{
		/* padding-left:80px;
		padding-right:80px;
		padding-bottom:0px; */
		/* padding-top: 0px; */
	}
	.AbsoluteBox{
		height: calc(100% + 40px);
		margin-top: -20px !important;
		margin-bottom: -20px !important;
		
	}
	.Button2{
		display: none;
	}
	.containerWeCover{
		padding-right:65px !important;
		padding-left:65px !important;
	}
}

@media (min-width: 1330px) {
	.containerWeCover{
		padding-right:0px !important;
		padding-left:0px !important;
	}
}



