
.container{
    height: 100%;
    width: 100%; 
    /* background-color: #F0F0F0; */
}

.sectionContainer{
    width: 100%;
    height: 100%;
    display: flex; 
    /* max-height: 626px; */
}

.textContainer{
    flex: 1;
    display: flex;
    flex-direction:row;
    flex-wrap:wrap; 
    align-content:center;
    /* background-color: #F0F0F0; */
    /* border: 3px solid blue; */
}

.sectionTitle{
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 47px;
    margin: 0;
    margin-bottom: 20px;
    margin-left: 131px;
}

.sectionSubtitle{
    text-align: left;
    line-height: 35px;
    width: 55%;
    color: #223644;
    font-size: 18px;
    margin-left: 131px;
    margin-top: 0;
}

.imgContainer{
    flex: 1;
    display: flex;
    max-height: 626px;
    /* border: 3px solid red; */
}

@media screen and (max-width: 1110px) {
    .textContainer{
        padding-top: 100px;
    }
    
    .sectionTitle, .sectionSubtitle{
        margin-left: 60px;
    }

    .sectionSubtitle{
        width: 80%;
    }
}



@media screen and (max-width: 883px) {

    .sectionContainer{
        max-height: 100% !important;
    }

    .sectionContainer{
        flex-direction: column;
        height: 100%;
    } 


    .textContainer{
        order: 1 !important;
        padding-top: 46px;
    }

    .imgContainer{
        order: 2 !important;
        /* flex: 2; */
    }

    .sectionTitle, .sectionSubtitle{
        margin-left: 50px;
    }
    .sectionTitle{
        font-size: 30px;
    }
    .sectionSubtitle{
        font-size:18px;
        width:90%;
    }
}

@media screen and (max-width: 695px) {
	.sectionTitle, .sectionSubtitle{
        margin-left: 15px;
    }
}

@media screen and (max-width: 320px) {
    .sectionSubtitle{
        font-size: 15px;
    }
   
}