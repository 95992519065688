.Content{
	margin-top: 80px;
	height: 100%;
	width: 100%;
	/*position: absolute;*/
	top: 0;
	left: 0;
	
}

@media(max-width: 699.98px){
	.Content{
		margin-top: 75px;
	}
}