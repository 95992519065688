.Toolbar {
	height: 80px;
	width: 100%;
	position: fixed;
	top:0;
	left: 0;
	background-color: #223644;
	display: flex;
	justify-content:space-between;
	align-items: center;
	padding:0 80px 0;
	box-sizing: border-box;
	z-index: 90;
}


.Toolbar nav{
	height: 100%;

}

/* .Logo{
	height: 80%;
} */

@media(max-width: 1099.98px){
	.DesktopOnly{
		display: none;
	}
	.Toolbar{
		padding:0 50px 0 50px;
	}
}



@media(max-width: 699.98px){
	.Toolbar{
		height: 75px;
		padding:0 15px 0 15px;
	}
}